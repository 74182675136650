import React, { useCallback, useEffect, useRef, useState } from 'react';
import OT from '@opentok/client';
import './openTokVideoChat.css';
import { IoMdExit } from "react-icons/io";
import { BsRecordCircle } from "react-icons/bs";
import { BsRecordCircleFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideoCam from "@mui/icons-material/Videocam";
import VideocamOff from "@mui/icons-material/VideocamOff";
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import ClosedCaptionDisabledIcon from '@mui/icons-material/ClosedCaptionDisabled';
import { useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface VideoChatProps {
  apiKey?: string;
  sessionId?: string;
  token?: string;
  serverBaseUrl?: string;
}

interface CaptionsData {
  id: string;
}

interface ArchiveData {
  id: string;
  status: string;
  error?: string;
}

interface SessionResponse {
  apiKey: string;
  sessionId: string;
  token: string;
}

interface OTError {
  message: string;
  code?: number;
  name?: string;
}

interface Transcription {
  text: string;
  timestamp: Date;
  speakerId: string;
  speakerName: string;
}

interface ConversationHistory {
  sessionId: string;
  startTime: Date;
  transcriptions: Transcription[];
}

const VideoChat: React.FC<VideoChatProps> = () => {
  const serverBaseUrl = process.env.REACT_APP_API_URL;
  const appRunningUrl = process.env.REACT_APP_URL;
  const [session, setSession] = useState<OT.Session | null>(null);
  const [publisher, setPublisher] = useState<OT.Publisher | null>(null);
  const [subscriber, setSubscriber] = useState<OT.Subscriber | null>(null);
  const [captions, setCaptions] = useState<CaptionsData | null>(null);
  const [archive, setArchive] = useState<ArchiveData | null>(null);
  const [showWaitingMsg, setShowWaitingMsg] = useState(true);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [isCaptionStarted, setIsCaptionStarted] = useState(false);
  const [isArchiveStarted, setIsArchiveStarted] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const captionsRemovalTimerRef = useRef<NodeJS.Timeout>();
  const connectionsRef = useRef(new Set<string>());
  const conversationHistoryRef = useRef<ConversationHistory>({
    sessionId: '',  // Provide default values
    startTime: new Date(),
    transcriptions: [],
  });
  const navigate = useNavigate();
  const publisherRef = useRef<HTMLDivElement>(null);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [screenSharePublisher, setScreenSharePublisher] = useState<OT.Publisher | null>(null);
  const [isScreenShareOn, setIsScreenShareOn] = useState(false);
  const { uniqueId } = useParams<{ uniqueId: string }>();

  useEffect(() => {
    const disableBackButton = (event: any) => {
      event.preventDefault();
      window.history.forward();
    };

    // Disable the back button
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', disableBackButton);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, []);

  const handleError = (error: OTError | undefined) => {
    if (error) {
      const errorMessage = error.message || 'An unknown error occurred';
      console.error('Error:', errorMessage);
      setError(errorMessage);

      // Handle specific OpenTok error codes
      if (error.code) {
        switch (error.code) {
          case 1004:
            console.error('Authentication error. Check your credentials.');
            break;
          case 1005:
            console.error('Invalid session ID.');
            break;
          case 1006:
            console.error('Connect failed.');
            break;
          case 1011:
            console.error('Invalid parameter values.');
            break;
          case 1013:
            console.error('No stream found.');
            break;
          case 1500:
            console.error('Unable to connect to the server.');
            break;
          default:
            console.error(`OpenTok error: ${error.code}`);
        }
      }

      // Attempt recovery based on error type
      if (error.name === 'OT_MEDIA_ERR_DEVICE_NOT_FOUND') {
        console.error('No camera/microphone found. Please check your devices.');
      } else if (error.name === 'OT_AUTHENTICATION_ERROR') {
        // Attempt to refresh credentials
        refreshSession();
      }
    }
  };

  const addTranscription = (text: string, speakerId: string, speakerName: string) => {
    
    const newTranscription = {
      text,
      timestamp: new Date(),
      speakerId,
      speakerName
    };

    conversationHistoryRef.current = {
      ...conversationHistoryRef.current,
      transcriptions: [
        ...conversationHistoryRef.current.transcriptions,
        newTranscription
      ]
    }
  };

  const refreshSession = async () => {
    try {
      const response = await fetch(`${serverBaseUrl}/session`);
      if (!response.ok) {
        throw new Error('Failed to refresh session');
      }
      const json: SessionResponse = await response.json();
      setApiKey(json.apiKey);
      setSessionId(json.sessionId);
      setToken(json.token);
      initializeSession(json.apiKey, json.sessionId, json.token);
    } catch (error) {
      handleError(error as OTError);
    }
  };

  const postData = async (url: string, data: object = {}) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      handleError(error as OTError);
      throw error; // Re-throw to handle in calling function
    }
  };

  const startCaptions = async () => {
    try {
      if (!sessionId || !token) {
        throw new Error('Session ID or token is missing');
      }
      const captionsData = await postData(`${serverBaseUrl}/captions/start`, {
        sessionId: sessionId,
        token: token,
      });
      setCaptions(captionsData);
      setIsCaptionStarted(true);
    } catch (error) {
      handleError(error as OTError);
      setIsCaptionStarted(false);
    }
  };

  const stopCaptions = async () => {
    if (!captions) return;
    try {
      await postData(`${serverBaseUrl}/captions/${captions.id}/stop`, {});
      setCaptions(null);
      setIsCaptionStarted(false);
    } catch (error) {
      handleError(error as OTError);
      // Keep current state if stop operation fails
    }
  };

  const startArchiving = async () => {
    try {
      if (!sessionId) {
        throw new Error('Session ID is missing');
      }
      const archiveData = await postData(`${serverBaseUrl}/archive/start`, {
        sessionId: sessionId,
      });
      if (archiveData.status !== 'started') {
        throw new Error(archiveData.error || 'Failed to start archive');
      }
      setArchive(archiveData);
      setIsArchiveStarted(true);
    } catch (error) {
      handleError(error as OTError);
      setIsArchiveStarted(false);
    }
  };

  const stopArchiving = async () => {
    if (!archive) return;
    try {
      const archiveData = await postData(`${serverBaseUrl}/archive/${archive.id}/stop`, {});
      if (archiveData.status !== 'stopped') {
        throw new Error(archiveData.error || 'Failed to stop archive');
      }
      setArchive(archiveData);
      setIsArchiveStarted(false);
    } catch (error) {
      handleError(error as OTError);
      // Keep current state if stop operation fails
    }
  };

  // const disconnectSession = (ses: OT.Session) => {
  //   try {
  //     if (ses) {
  //       ses.disconnect();
  //       console.log(conversationHistory);
        
  //       console.log('Disconnected from the session');
  //     }
  //   } catch (error) {
  //     handleError(error as OTError);
  //   }
  // };

  const disconnectSession = (ses: OT.Session) => {
    console.log("disconnectSession");
    
    try {
      if (ses) {
        // This will now have access to the current session state
        console.log('conversationHistory: ',  conversationHistoryRef.current);
        ses.disconnect();
        console.log('Disconnected from the session');
        // window.close();
        navigate('/');
      }
    } catch (error) {
      handleError(error as Error | OTError);
    }
  };

  const handleLeave = () => {
    try {
      if (session) {
        session.signal({
          type: 'msg',
          data: 'leavedFromCall',
        }, (error) => {
          if (error) {
            handleError(error as OTError);
          }
          disconnectSession(session);
          if (publisher) {
            publisher.destroy(); // Stop camera & microphone access
          }
          if (session) {
            session.disconnect(); // Disconnect from the OpenTok session
          }
        });
      }
    } catch (error) {
      handleError(error as OTError);
      // Attempt forced disconnect on error
      if (session) {
        disconnectSession(session);
      }
    }
  };

  const initializeSession = (apiKey: any, sessionId: any, token: any) => {
    try {
      if (!apiKey || !sessionId || !token) {
        throw new Error('Missing required credentials');
      }
      const newSession = OT.initSession(apiKey, sessionId);

      OT.on("exception", function(event) {
        console.error('OpenTok Exception:', event.message);
        handleError(new Error(event.message));
      });

      newSession.on('connectionCreated', (event) => {
        try {
          if (event.connection.connectionId !== newSession.connection?.connectionId) {
            connectionsRef.current.add(event.connection.connectionId);
            setShowWaitingMsg(false);
          }
        } catch (error) {
          handleError(error as OTError);
        }
      });

      newSession.on('connectionDestroyed', (event) => {
        try {
          connectionsRef.current.delete(event.connection.connectionId);
          setShowWaitingMsg(true);
        } catch (error) {
          handleError(error as OTError);
        }
      });

      newSession.on('streamCreated', async (event) => {
        try {
          console.log("XXXX ", event.stream.videoType);
          
          const subscriberOptions: any = {
            insertMode: 'append',
            width: '100%',
            height: '100%',
          };

          newSession.on('signal:msg', (event: any) => {
            if (event.data === 'leavedFromCall') {
              disconnectSession(newSession);
            }
          });
          let parentElementId = 'subscriber';
          if (event.stream.videoType === 'screen'){
            parentElementId = 'screen-share-subscriberVideo';
            setIsScreenShareOn(true);
          }
          const newSubscriber = newSession.subscribe(
            event.stream,
            parentElementId,
            subscriberOptions,
            handleError
          );
          setSubscriber(newSubscriber);

          try {
            await newSubscriber.subscribeToCaptions(true);
          } catch (err) {
            console.warn('Caption subscription error:', err);
          }

          newSubscriber.on('captionReceived', (captionEvent) => {
            try {
              const subscriberContainer = document.querySelector('.OT_subscriber');
              if (!subscriberContainer) return;

              const oldCaptionBox = subscriberContainer.querySelector('.caption-box');
              if (oldCaptionBox) oldCaptionBox.remove();

              const captionBox = document.createElement('div');
              captionBox.classList.add('caption-box');
              captionBox.textContent = captionEvent.caption;
              console.log(captionEvent.caption);
              
              addTranscription(
                captionEvent.caption,
                event.stream.connection.connectionId,
                'Remote Participant'
              );

              clearTimeout(captionsRemovalTimerRef.current);
              captionsRemovalTimerRef.current = setTimeout(() => {
                captionBox.textContent = '';
              }, 5000);

              subscriberContainer.appendChild(captionBox);
            } catch (error) {
              handleError(error as OTError);
            }
          });
        } catch (error) {
          handleError(error as OTError);
        }
      });

      newSession.on('streamDestroyed', (event) => {
        console.log("streamDestroyed ", event);
        if(event.stream.videoType === 'screen') {
          setIsScreenShareOn(false);
        }
        setSubscriber(null);
      });

      const publisherOptions: any = {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        publishCaptions: true,
        publishVideo: false,
        publishAudio: false 
      };

      const newPublisher = OT.initPublisher('publisher', publisherOptions, handleError);
      setPublisher(newPublisher);

      newSession.connect(token, (error) => {
        if (error) {
          handleError(error);
        } else {
          newSession.publish(newPublisher, handleError);
          setSession(newSession);
        }
      });
    } catch (error) {
      handleError(error as OTError);
    }
  };

  useEffect(() => {
    const initSession = async () => {
      try {
        if (process.env.API_KEY && process.env.TOKEN && process.env.SESSION_ID) {
          setApiKey(process.env.API_KEY);
          setSessionId(process.env.SESSION_ID);
          setToken(process.env.TOKEN);
          initializeSession(process.env.API_KEY, process.env.SESSION_ID, process.env.TOKEN);
        } else if (serverBaseUrl) {
          // const response = await fetch(`${serverBaseUrl}/session`);
          const response = await fetch(`${serverBaseUrl}/room/${uniqueId}`);
          if (!response.ok) {
            throw new Error('Failed to get session data');
          }
          const json: SessionResponse = await response.json();
          setApiKey(json.apiKey);
          setSessionId(json.sessionId);
          setToken(json.token);
          initializeSession(json.apiKey, json.sessionId, json.token);
        }
      } catch (error) {
        handleError(error as OTError);
        alert('Failed to get OpenTok sessionId and token.');
      }
    };

    initSession();

    return () => {
      if (session) {
        try {
          disconnectSession(session);
        } catch (error) {
          handleError(error as OTError);
        }
      }
    };
  }, []);

  const toggleMute = () => {
    try {
      if (publisher) {
        publisher.publishAudio(!isMuted);
        setIsMuted(!isMuted);
      }
    } catch (error) {
      handleError(error as OTError);
    }
  };

  const toggleVideo = () => {
    try {
      if (publisher) {
        publisher.publishVideo(!isVideoOff);
        setIsVideoOff(!isVideoOff);
      }
    } catch (error) {
      handleError(error as OTError);
    }
  };

  const startScreenSharing = () => {
    OT.checkScreenSharingCapability((response: OT.ScreenSharingCapabilityResponse) => {
      console.log(response);
      
      if (!response.supported || response.extensionRegistered === false) {
        alert('Screen sharing not supported');
      } else if (response.extensionInstalled === false) {
        alert('Please install the screen sharing extension and refresh the page');
      } else {
        const publisherOptions: OT.PublisherProperties = {
          videoSource: 'screen',
          publishAudio: false,
          maxResolution: { width: 1920, height: 1080 }
        };
        const screenPublisher = OT.initPublisher(publisherRef.current!, publisherOptions, (error) => {
          if (error) {
            handleError(error);
          } else {
            session?.publish(screenPublisher, handleError);
            setScreenSharePublisher(screenPublisher);
            setIsScreenSharing(true);
          }
        });
      }
    });
  };

  const stopScreenSharing = () => {
    if (screenSharePublisher) {
      console.log("stopScreenSharing");
      
      session?.unpublish(screenSharePublisher);
      setScreenSharePublisher(null);
      setIsScreenSharing(false);
      setIsScreenShareOn(false);
    }
  };

  const copyLinkToClipBoard = () => {
    const linkToCopy = `${appRunningUrl}/waiting-room/${uniqueId}`;
    console.log("linkToCopy ", linkToCopy);
    navigator.clipboard.writeText(linkToCopy).then(() => {
      // setCopySuccess('Copied!');
    }).catch(err => {
      // setCopySuccess('Failed to copy!');
      console.error('Failed to copy: ', err);
    });
    
  }

  return (
    <div className="video-container-main">
      {error && <div className="error-message">{error}</div>}
      <div className="video-grid">
        <div className="video-left">
          <div id="publisher" className="video-box"></div>
        </div>
        <div className="video-right">
          <div id="subscriber" className="video-box"></div>
        </div>
        {showWaitingMsg && <div className="waiting-msg">Waiting for other party to join</div>}
        <div id="network-status" />
      </div>

      <div className="controls-container">
        <div className="button-group">
          <div className="control-group">
            {isMuted ? (
              <button onClick={toggleMute} className='common-button-selected'>
                <MicIcon />
              </button>
            ) : (
              <button onClick={toggleMute} className='common-button'>
                <MicOffIcon />
              </button>
            )}
          </div>
          <div className="control-group">
            {isVideoOff ? (
              <button onClick={toggleVideo} className='common-button-selected'>
                <VideoCam />
              </button>
            ) : (
              <button onClick={toggleVideo} className='common-button'>
                <VideocamOff />
              </button>
            )}
          </div>
          <div className="control-group">
            {!isCaptionStarted ? (
              <button onClick={startCaptions} className='common-button'>
                <ClosedCaptionIcon />
              </button>
            ) : (
              <button onClick={stopCaptions} className='common-button-selected'>
                <ClosedCaptionDisabledIcon />
              </button>
            )}
          </div>
        <div className="control-group">
          {!isArchiveStarted ? (
            <button onClick={startArchiving} className='common-button' disabled={!!archive}>
              <BsRecordCircle />
            </button>
          ) : (
            <button onClick={stopArchiving} className='common-button-selected' disabled={!archive}>
              <BsRecordCircleFill />
            </button>
          )}
        </div>
        {/* <div className="control-group">
          {!isScreenSharing ? (
            <button onClick={startScreenSharing} className='common-button'>
              <ScreenShareIcon />
            </button>
          ) : (
            <button onClick={stopScreenSharing} className='common-button-selected'>
              <StopScreenShareIcon />
            </button>
          )}
        </div> */}
        <div className="control-group">
          <button className="common-button" onClick={copyLinkToClipBoard}>
            <ContentCopyIcon />
          </button>
        </div>
        <div className="control-group">
          <button className="exit-button" onClick={handleLeave}>
            <IoMdExit />
          </button>
        </div>
      </div>
    </div>
    {/* <div ref={subscriberRef} className='screen-share-subscriberVideo' style={{display: subscriber ? 'block' : 'none'}} /> */}
    {isScreenShareOn && <div className="screen-share-wrapper">
      <div className='screen-share-container'>
        <div className='screen-share-header'>
          <h2 className='screen-share-title'>Screen Sharing Session</h2>
        </div>
        <div className='screen-share-videoContainer'>
          <div className='screen-share-publisherVideo' style={{width: subscriber ? '20%' : '100%'}}/>
          <div 
            id="screen-share-subscriberVideo" 
            style={{display: subscriber ? 'block' : 'none'}}
          />
        </div>
      </div>
    </div>}
  </div>
  );
};

export default VideoChat;