import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const Home = () => {
  const navigate = useNavigate();

  const generateUniqueString = () => {
    const timestamp = Date.now().toString(36);
    console.log("currentDate ",timestamp);
    
    const uuid = uuidv4();
    console.log("uuid ",uuid);
    
    return `${timestamp}-${uuid}`;
  };

  const clickJoinSessionBtn = () => {
    // window.open('http://localhost:3000/join-session', '_blank');
    const uniqueUrl = generateUniqueString();
    console.log("uniqueurl ",uniqueUrl);
    
    navigate(`/waiting-room/${uniqueUrl}`)
  }
  return (
    <div>
        <h5>
          <button onClick={clickJoinSessionBtn}>
            join-session
          </button>
        </h5>
    </div>
  )
}

export default Home