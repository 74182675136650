import './App.css';
import OpenTokVideoChat from './components/VideoRoom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import WaitingRoom from './components/WaitingRoom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const primary = process.env.REACT_APP_PALETTE_PRIMARY || "#ff8000";
const secondary = process.env.REACT_APP_PALETTE_SECONDARY || "#d96d00";

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Customize the primary color
    },
    secondary: {
      main: '#dc004e', // Customize the secondary color
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/waiting-room/:uniqueId' element={<WaitingRoom />} />
            <Route path='/join-session/:uniqueId' element={<OpenTokVideoChat/>} />
          </Routes>
        </Router>
      </ThemeProvider>
      
    </div>
  );
}

export default App;
