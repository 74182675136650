import React, { useEffect, useState, useRef } from 'react';
import OT from '@opentok/client';
import { useNavigate } from 'react-router-dom';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import './waitingRoom.css';
import { useParams } from 'react-router-dom';

interface Device {
  deviceId: string;
  label: string;
  kind: string;
}

interface AudioLevelUpdatedEvent {
  audioLevel: number;
}

interface PublisherCycleVideoResponse {
  deviceId: string;
}

const OpenTokDevicePublisher: React.FC = () => {
  const [audioDevices, setAudioDevices] = useState<Device[]>([]);
  const [videoDevices, setVideoDevices] = useState<Device[]>([]);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState<string>('');
  const [selectedVideoDevice, setSelectedVideoDevice] = useState<string>('');
  const [isPublishing, setIsPublishing] = useState(false);
  const [audioLevel, setAudioLevel] = useState(0);
  const publisherRef = useRef<any>(null);
  const navigate = useNavigate();
  // const publisherDivRef = useRef<HTMLDivElement>(null);
  const { uniqueId } = useParams<{ uniqueId: string }>();

  const populateDeviceSources = async () => {
    try {
      // First get user media to ensure we have permission and can get device labels
      const stream = await OT.getUserMedia();
      
      // Get devices
      OT.getDevices((err, devices) => {
        if (err) {
          alert('getDevices error ' + err.message);
          return;
        }
        console.log(devices);
        
        if (devices){
          const audioInputs = devices.filter(device => device.kind === 'audioInput');
          const videoInputs = devices.filter(device => device.kind === 'videoInput');
          console.log(audioInputs);
          console.log(videoInputs);
          
          
          setAudioDevices(audioInputs);
          setVideoDevices(videoInputs);

          if (audioInputs.length) setSelectedAudioDevice(audioInputs[0].deviceId);
          if (videoInputs.length) setSelectedVideoDevice(videoInputs[0].deviceId);

          // Stop the tracks after we've gotten the labels
          stream.getTracks().forEach(track => track.stop());
        }
        
      });
    } catch (err) {
      console.error('Error getting user media:', err);
    }
  };

  useEffect(() => {
    populateDeviceSources();
  }, []);

  const setupAudioLevelMeter = (publisher: any) => {
    let movingAvg: number | null = null;
    
    publisher.on('audioLevelUpdated', (event: AudioLevelUpdatedEvent) => {
      if (movingAvg === null || movingAvg <= event.audioLevel) {
        movingAvg = event.audioLevel;
      } else {
        movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
      }

      // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
      let logLevel = (Math.log(movingAvg) / Math.LN10) / 1.5 + 1;
      logLevel = Math.min(Math.max(logLevel, 0), 1);
      setAudioLevel(logLevel);
    });
  };

  const handlePublish = () => {
    // if (!publisherDivRef.current) return;

    // publisherRef.current = OT.initPublisher(
    //   publisherDivRef.current,
    //   {
    //     audioSource: selectedAudioDevice,
    //     videoSource: selectedVideoDevice
    //   },
    //   (err) => {
    //     if (err) {
    //       alert('Publish error ' + err.message);
    //       return;
    //     }
    //     setIsPublishing(true);
    //     setupAudioLevelMeter(publisherRef.current);
    //   }
    // );
    console.log("XXXXXXXXXXXXXXXXXXXXXXX");
    
    navigate(`/join-session/${uniqueId}`);
  };

  const handleAudioSourceChange = async (deviceId: string) => {
    if (!publisherRef.current) return;

    try {
      setSelectedAudioDevice(deviceId);
      await publisherRef.current.setAudioSource(deviceId);
    } catch (err) {
      alert(`setAudioSource failed: ${(err as Error).message}`);
    }
  };

  const handleCycleVideo = async () => {
    if (!publisherRef.current) return;

    try {
      const response: PublisherCycleVideoResponse = await publisherRef.current.cycleVideo();
      setSelectedVideoDevice(response.deviceId);
    } catch (err) {
      alert('cycleVideo error ' + (err as Error).message);
    }
  };

  return (
    <div className="container">
      <div className="content-wrapper">
        
        <div className="form-container">
        <h1 className="heading">Publish Devices Sample</h1>
          <FormControl className="form-control">
            <InputLabel id="audio-source-label">Select Audio Source</InputLabel>
            <Select
              labelId="audio-source-label"
              id="audio-source"
              value={selectedAudioDevice}
              onChange={(e) => handleAudioSourceChange(e.target.value)}
              disabled={!isPublishing}
              label="Select Audio Source"
            >
              {audioDevices.map((device, index) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Audio Input ${index + 1}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="form-control">
            <InputLabel id="video-source-label">Select Video Source</InputLabel>
            <Select
              labelId="video-source-label"
              id="video-source"
              value={selectedVideoDevice}
              onChange={(e) => setSelectedVideoDevice(e.target.value)}
              disabled={isPublishing}
              label="Select Video Source"
            >
              {videoDevices.map((device, index) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Video Input ${index + 1}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className="button-container">
            <Button
              variant="contained"
              color="primary"
              onClick={handlePublish}
              disabled={!selectedAudioDevice || !selectedVideoDevice}
              className="action-button"
            >
              Join
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenTokDevicePublisher;